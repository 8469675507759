import axios from "axios";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import ModalShare from "./modals/ModalShare";
import ButtonDownload from "./buttons/ButtonDownload";

const DownloadDetails = ({item}) => {

  const listImages = item.images.map((url, i) => {
  return (
  <div key={i} className={i === 0 ? "carousel-item active" : "carousel-item"}>
    <img className="w-100 border-radius-lg shadow-lg" src={url} alt="Download Image"/>
  </div>
    );
  });

  const listTags = item.tags.map((tag) => {
    return(
      <span className="badge bg-gradient-primary me-1">{tag}</span>
    );
  });

  const listAdvice = item.advice.map((advice) => {
    return(
      <p className="d-flex">
      <span className={`material-icons-round ${advice.class}`}>{advice.icon}</span>&nbsp;
      {advice.message}
      </p>
    );
  });

  const dwdButton = async(e) => {
    // e.preventDefault();
    const updItem = (await axios.get('https://us-central1-sledgemods-site.cloudfunctions.net/api/downloads/'+item._id)).data;
    const updDwd = {qtyD: ++updItem.qtyD}
    await axios.put('https://us-central1-sledgemods-site.cloudfunctions.net/api/downloads/' + item._id , updDwd);
    saveAs(item.downloadLink, item.title);
  };

  const timestamp = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}).format(item.timestamp);

  return (
    <>
      <div className="container px-4 pt-4">
        <section className="mt-5 py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-12 my-3">
                <div
                  id="carouselExampleControls"
                  className="carousel slide w-100 border-radius-lg shadow-lg"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    {listImages}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="col-md-7 col-12 my-3">
                <h3 className="text-gradient text-primary">{item.title}</h3>
                <div className="my-2">
                  {listTags}
                </div>
                <div className="row align-items-center">
                  <p className="col-sm-3 my-2 font-weight-normal">Author: <span className="text-primary">{item.author}</span></p>
                  <p className="col-sm-3 my-2">Uploaded: <span className="text-primary">{timestamp}</span></p>
                  <p className="col-sm-3 my-2">Downloads: <span className="text-primary">{item.qtyD}</span></p>
                  <p className="col-sm-3 my-2">File Size: <span className="text-primary">{item.size}</span></p>
                </div>
                <p className="pe-md-5 mb-3">{item.description}</p>
                {listAdvice}
                <p />
                  <ButtonDownload className="my-2" btn={dwdButton}/>
                  <ModalShare className="my-2" currentURL={window.location.href} classStr={'bg-gradient-primary me-4 btn-lg px-3'}/>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container px-4 py-3 mb-3">
        <div className="row">
          <div className="col-lg-6 mx-auto text-center">
            <h3 className="mb-0 text-primary">Do you enjoy my content? 😁</h3>
            <p className="lead">
              Please, make a donation, It would help me a lot 👋
            </p>
            <Link to={'/donate'} type="button" className="btn bg-gradient-primary" href="">
              Donate
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default DownloadDetails