import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FacebookShareButton, FacebookIcon, TelegramShareButton, TelegramIcon, RedditShareButton, RedditIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';

const ModalShare = ({currentURL, classStr}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
      <Button className={classStr} onClick={handleShow}>
      <i className="material-icons me-2" aria-hidden="true">share</i>Share
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <div className='bg-gradient-dark rounded-3 border-1 border border-primary'>
          <div className="modal-header border-0" closeButton>
            <h5 className="modal-title text-white">
              Share
            </h5>
            <button type="button" className="btn-close" onClick={handleClose} />
          </div>
          <div className="modal-body border-0">
            <div className="row justify-content-center mb-4">
              <div className='col text-center my-2'>
                <RedditShareButton url={currentURL}>
                  <RedditIcon size={40} round={true}></RedditIcon>
                </RedditShareButton>
              </div>
              <div className='col text-center my-2'>
                <TwitterShareButton url={currentURL}>
                  {/* <TwitterIcon size={40} round={true}></TwitterIcon> */}
                  <div className='className="btn rounded-circle bg-gradient-dark p-2 d-flex align-items-center justify-content-center mb-0"'>
                  <iconify-icon icon="line-md:twitter-x" class="text-white text-center fs-4" />
                  </div>
                </TwitterShareButton>
              </div>
              <div className='col text-center my-2'>
                <FacebookShareButton url={currentURL}>
                  <FacebookIcon size={40} round={true}></FacebookIcon>
                </FacebookShareButton>
              </div>
              <div className='col text-center my-2'>
                <WhatsappShareButton url={currentURL}>
                  <WhatsappIcon size={40} round={true}></WhatsappIcon>
                </WhatsappShareButton>
              </div>
              <div className='col text-center my-2'>
                <TelegramShareButton url={currentURL}>
                  <TelegramIcon size={40} round={true}></TelegramIcon>
                </TelegramShareButton>
              </div>
            </div>
            <div className="input-group mb-3 rounded-0">
              <input type="text" className="form-control bg-light" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled value={currentURL}/>
            </div>
            <div className='d-flex align-items-center justify-content-center'>
              <button class="btn bg-gradient-primary d-flex align-items-center mb-1" alt="Copy to Clipboard" onClick={() => { navigator.clipboard.writeText(currentURL) }}><span className='me-2'>Copy to Clipboard</span><i class="material-icons-round">link</i></button>
            </div>
          </div>
          <div className="modal-footer border-0">
            <button type="button" className="btn bg-gradient-light" onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalShare