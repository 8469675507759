const Faq = () => {
    return (
        <>
  <div className="container px-4 py-4" />
  <div className="container col-xxl-12 px-4 py-3 my-5">
    <div className="row justify-content-center">
      <div className="col-lg-7">
        <h2 className="fw-bold lh-1 mb-3 text-white text-center">Guides</h2>
        <div className="container">
          <div className="p-3 info-horizontal">
            <p>
              Replacing textures can be a great way to improve the look of your
              games. For example, you can replace the low-resolution textures in
              a game with high-resolution ones. This can make the game look much
              sharper and more detailed.
            </p>
            <p>
              Dumping textures can be useful if you want to create your own
              texture packs. Texture packs are collections of textures that you
              can use to replace the textures in a game. This can be a great way
              to customize the look of your games.
            </p>
            <p>
              In this section, you will find guides on how to replace and dump
              textures for Dolphin Nintendo Gamecube emulator. I'll add more
              guides on the future. <br /> If you have any question send me a
              message to my{" "}
              <a className="" href="https://x.com/SledgeLIX">
                <iconify-icon icon="line-md:twitter-x" className="text-white" />{" "}
                account
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container col-xxl-12 px-4 py-2 my-5" id="texdolphin">
    <div className="row justify-content-center">
      <div className="col-lg-7">
        <h3 className="fw-bold lh-1 mb-3 text-white text-center">
          How to replace textures on Dolphin Emulator
        </h3>
        <div className="container">
          <div className="p-3 info-horizontal">
            <div className="icon icon-shape rounded-3 bg-gradient-primary shadow-primary d-flex align-items-center justify-content-center">
              <span className="text-white fs-3">1</span>
            </div>
            <div className="description ps-3">
              <p className="mb-0">
                Enable "Load custom textures" box in Graphics → Advanced
                Settings in Dolphin Emulator.
              </p>
            </div>
          </div>
          <div className="p-3 info-horizontal">
            <div className="icon icon-shape rounded-3 bg-gradient-primary shadow-primary d-flex align-items-center justify-content-center">
              <span className="text-white fs-3">2</span>
            </div>
            <div className="description ps-3">
              <p className="mb-0">
                Extract textures into: <br /> Users\[yourUser]\Documents\Dolphin
                Emulator\Load\Textures\[gameID]
              </p>
            </div>
          </div>
          <div className="p-3 info-horizontal">
            <div className="icon icon-shape rounded-circle bg-gradient-info shadow-info d-flex align-items-center justify-content-center">
              <span className="fs-3 text-white">i</span>
            </div>
            <div className="description ps-3">
              <p className="mb-0">
                Once you start the game for the first time, a folder with the
                Game ID would be created on Documents\Dolphin
                Emulator\Load\Textures\
              </p>
              <p>
                For example: Nascar Thunder 2003 game ID its: GNCE69 <br /> You
                can find all games ID on this{" "}
                <a href="https://www.gametdb.com/">Link</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container col-xxl-12 px-4 py-2 my-5" id="dumpdolphin">
    <div className="row justify-content-center">
      <div className="col-lg-7">
        <h3 className="fw-bold lh-1 mb-3 text-white text-center">
          Dumping Textures on Dolphin Emulator
        </h3>
        <div className="container">
          <div className="p-3 info-horizontal">
            <div className="icon icon-shape rounded-3 bg-gradient-primary shadow-primary d-flex align-items-center justify-content-center">
              <span className="text-white fs-3">1</span>
            </div>
            <div className="description ps-3">
              <p className="mb-0">
                Open the Dolphin Emulator and load the game you want to replace
                textures for
              </p>
            </div>
          </div>
          <div className="p-3 info-horizontal">
            <div className="icon icon-shape rounded-3 bg-gradient-primary shadow-primary d-flex align-items-center justify-content-center">
              <span className="text-white fs-3">2</span>
            </div>
            <div className="description ps-3">
              <p className="mb-0">Go to Graphics &gt; Advanced &gt; Utility</p>
            </div>
          </div>
          <div className="p-3 info-horizontal">
            <div className="icon icon-shape p-3 rounded-3 bg-gradient-primary shadow-primary d-flex align-items-center justify-content-center">
              <span className="text-white fs-3">3</span>
            </div>
            <div className="description ps-3">
              <p className="mb-0">
                Check the boxes next to "Dump Textures" and "Dump Mip Maps".
              </p>
            </div>
          </div>
          <div className="p-3 info-horizontal">
            <div className="icon icon-shape rounded-3 bg-gradient-primary shadow-primary d-flex align-items-center justify-content-center">
              <span className="text-white fs-3">4</span>
            </div>
            <div className="description ps-3">
              <p className="mb-0">
                Then, the game will start to dump the textures shown rendered
                ingame. <br /> A folder with the Game ID would be created on
                Documents\Dolphin Emulator\Dump\Textures\ containing dumped
                textures
                <br />
              </p>
            </div>
          </div>
          <div className="p-3 info-horizontal">
            <div className="icon icon-shape d-flex align-items-center justify-content-center">
              <span className="material-icons-round text-warning fs-1">
                warning
              </span>
            </div>
            <div className="description ps-3">
              <p className="mb-0">
                Take care when you do this step. Once you dumped the texture
                that you need, <br /> uncheck the boxes next to "Dump Textures"
                in order to avoid excessive use of an SSD drive.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>
    )
  }

const Donate = () => {
    return (
      <section className="py-7">
  <div className="container">
    <div className="row my-4">
      <div className="col-lg-6 mx-auto text-center">
        <h2 className="text-gradient text-primary mb-3">Donations</h2>
        <p className="lead">
          I believe in the power of creativity and community, your support
          allows me to continue providing a platform where game modding can
          thrive. If you've enjoyed the mods that I created or have benefited
          from the resources I offer, consider make a contribution. Your
          generous donations directly support the growth and sustainability of
          my site, and encourage me to keep pushing the boundaries of game
          customization.
        </p>
        <a href="https://www.buymeacoffee.com/Sledgelix"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=Sledgelix&button_colour=FFDD00&font_colour=000000&font_family=Lato&outline_colour=000000&coffee_colour=ffffff" /></a>
      </div>
    </div>
    <div className="row my-4 justify-content-center gap-2">
      <div className="col-lg-3">
        <div className="info-horizontal bg-gradient-info border-radius-xl d-block d-md-flex p-4">
          <div className="ps-0 ps-md-3 mt-3 mt-md-0">
            <h5 className="text-white text-center d-flex align-items-center justify-content-center">
              <iconify-icon icon="logos:paypal" />
              &nbsp; Paypal
            </h5>
            <p className="text-white">
              Support effortlessly with PayPal! Securely contribute
              using your PayPal account. Click the "Donate" button to contribute today!
            </p>
            <div className="d-flex justify-content-center"><a href="https://www.paypal.com/donate/?hosted_button_id=X4CSPEW92WDUN" className="btn btn-round btn-sm bg-gradient-warning px-4 py-1 my-0">Donate</a></div>
          </div>
        </div>
      </div>
      <div className="col-lg-3">
          <div className="info-horizontal bg-gradient-warning border-radius-xl d-block d-md-flex p-4">
            <div className="ps-0 ps-md-3 mt-3 mt-md-0">
              <h5 className="text-white d-flex align-items-center justify-content-center">
                <iconify-icon icon="simple-icons:binance" />
                &nbsp; Binance Pay
              </h5>
              <p className="text-white">
                Easily send
                contributions to SledgeMods using the Binance Pay wallet. Scan the
                QR code via Binance App.
              </p>
              <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-round btn-sm bg-gradient-dark px-4 py-1 my-0"
                data-bs-toggle="modal"
                data-bs-target="#binanceModal"
              >
                Donate
              </button>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="binanceModal"
            tabIndex={-1}
            aria-labelledby="binanceModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div
                style={{ backgroundColor: "transparent" }}
                className="modal-content bg-gradient-dark border-1 border border-primary"
              >
                <div className="modal-header border-0">
                  <h5 className="modal-title text-white">
                    Thanks for your donation! 😊
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body border-0 text-center">
                  <img
                    src="./assets/img/BinancePayQR.png"
                    alt=""
                    className="rounded-3"
                  />
                </div>
                <div className="modal-footer border-0">
                  <button
                    type="button"
                    className="btn bg-gradient-light"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="col-lg-3">
          <div className="info-horizontal bg-gradient-light border-radius-xl d-block d-md-flex p-4">
            <div className="ps-0 ps-md-3 mt-3 mt-md-0">
              <h5 className="text-warning d-flex align-items-center justify-content-center">
                <iconify-icon icon="logos:metamask-icon" />
                &nbsp; Metamask Wallet
              </h5>
              <p className="text-warning">
                Contribute to SledgeMods using your decentralized wallet.
                In order to donate Scan the QR Code or copy and paste my wallet address
              </p>
              <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-round btn-sm bg-gradient-dark px-4 py-1 my-0"
                data-bs-toggle="modal"
                data-bs-target="#walletModal"
              >
                Donate
              </button>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="walletModal"
            tabIndex={-1}
            aria-labelledby="walletModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div
                style={{ backgroundColor: "transparent" }}
                className="modal-content bg-gradient-dark border-1 border border-primary"
              >
                <div className="modal-header border-0">
                  <h5 className="modal-title text-white">
                    Thanks for your donation! 😊
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body border-0 text-center">
                  <img
                    src="./assets/img/0xE42153eA012DB2c5c3D378Ecd2BA7EE189a5e7d8_qr.png"
                    alt=""
                    className="rounded-3"
                  />
                  <br />
                  <br />
                  <button
                    className="btn btn-lg bg-gradient-primary d-flex align-items-center"
                    onclick="copyText()"
                    alt="Copy to Clipboard" onClick={() => { navigator.clipboard.writeText('0xE42153eA012DB2c5c3D378Ecd2BA7EE189a5e7d8') }}
                  >
                    <span id="textbox">
                      0xE42153eA012DB2c5c3D378Ecd2BA7EE189a5e7d8
                    </span>
                    &nbsp;<i className="material-icons-round">content_copy</i>
                  </button>
                </div>
                <div className="modal-footer border-0 justify-content-center">
                  <button
                    type="button"
                    className="btn bg-gradient-light"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <hr className="horizontal dark my-5" />
  </div>
</section>

    )
  }

export { Faq, Donate };