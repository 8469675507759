import axios from "axios";
import coverFront from '../assets/img/coverFront.png';
import coverBack from '../assets/img/coverBack.png';
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import ModalShare from "./modals/ModalShare";
import ModalVideo from "./modals/ModalVideo";
import ButtonDownload from "./buttons/ButtonDownload";

const NT2000 = ({items}) => {
    const item = items.find((item) => item.customURL === '/NT2000');
     
    const dwdButton = async(e) => {
        // e.preventDefault();
        const updItem = (await axios.get('https://us-central1-sledgemods-site.cloudfunctions.net/api/downloads/'+item._id)).data;
        const updDwd = {qtyD: ++updItem.qtyD}
        await axios.put('https://us-central1-sledgemods-site.cloudfunctions.net/api/downloads/' + item._id , updDwd);
        saveAs(item.downloadLink, `${item.title} ${item.advice[0].message.slice(0,-8)}.zip`)
      };
    
    const timestamp = new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(item.timestamp);

    const listImages = item.images.map((url, i) => {
        return (
        <div key={i} className={i === 0 ? "carousel-item active" : "carousel-item"}>
          <img className="w-100 border-radius-lg shadow-lg" src={url} alt="Download Image"/>
        </div>
          );
    });
      
    const listTags = item.tags.map((tag) => {
        return(
        <span className="badge bg-gradient-primary me-1">{tag}</span>
        );
    });
      
    const listAdvice = item.advice.map((advice) => {
        return(
        <p className="d-flex">
        <span className={`material-icons-round ${advice.class}`}>{advice.icon}</span>&nbsp;
        {advice.message}
        </p>
        );
    });

  return (
    <>
  <div className="container px-4 py-5" />
    <div className="container col-xxl-10 px-4 py-3">
      <div className="row flex-lg-row align-items-center g-5 pb-3 pt-5">
        <div className="col-lg-5 d-flex justify-content-center">
          <div className="myCard">
            <div className="innerCard">
              <div
                className="frontSide rounded-2"
                style={{
                  backgroundImage:
                  "url("+ coverFront +")",
                  backgroundSize: "cover"
                }}
              ></div>
              <div
                className="backSide rounded-2"
                style={{
                  backgroundImage:
                  "url("+ coverBack +")",
                  backgroundSize: "cover"
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <h1 className="display-5 fw-bold lh-1 text-white">
            {item.title}
          </h1>
          <div className="my-3">
              {listTags}
          </div>
          <div className="row align-items-center">
              <p className="col-sm-3 my-2 font-weight-normal text-white">Author: <span className="text-primary">{item.author}</span></p>
              <p className="col-sm-3 my-2 font-weight-normal text-white">Uploaded: <span className="text-primary">{timestamp}</span></p>
              <p className="col-sm-3 my-2 font-weight-normal text-white">Downloads: <span className="text-primary">{item.qtyD}</span></p>
              <p className="col-sm-3 my-2 font-weight-normal text-white">File Size: <span className="text-primary">{item.size}</span></p>
            </div>
          <p className="lead" align="justify">
            {item.description}
            <br />
          </p>
          {listAdvice}
          <div className="d-grid d-md-flex justify-content-md-start">
            <ButtonDownload className="my-2" btn={dwdButton}/>
            <ModalVideo btnText={'Trailer'} classStr={'btn bg-gradient-primary btn-lg px-3 me-4'} videoURL={'https://www.youtube.com/embed/GWkvr1grBoY?si=ut3dFn4pbO_YvAoN'}></ModalVideo>
            {/* <ModalVideo btnText={'Install Guide'} classStr={'btn bg-gradient-primary btn-lg px-3 me-4'} videoURL={'https://youtu.be/oDqcgYGsYI4'}></ModalVideo> */}
            {/* <a type="button" className="btn bg-gradient-primary btn-lg px-3 me-4" href="#instructions">
              <i className="material-icons me-2" aria-hidden="true"> description</i>
              ReadMe
            </a> */}
            {/* <a type="button" className="btn bg-gradient-primary btn-lg px-3 me-4" href="#credits">
              <i className="material-icons me-2" aria-hidden="true">reorder</i>
              Credits
            </a> */}
            <ModalShare currentURL={window.location.href} classStr={"btn bg-gradient-primary btn-lg px-3 me-4"}/>
          </div>
        </div>
      </div>
    </div>
    <div className="container col-xxl-12 px-4 py-3 my-2">
      <div className="col-lg-10 mx-auto py-3">
        <h2 className="fw-bold lh-1 mb-5 text-center text-white">
          Main Features
        </h2>
        <div className="row p-2">
          <div className="col-md-3 position-relative">
            <div className="p-3 text-center">
              <h1 className="text-gradient text-primary">
                <span className="material-icons fs-1" aria-hidden="true">
                  brush
                </span>
              </h1>
              <h5 className="mt-3 text-white">Paint Schemes</h5>
              <p className="text-sm font-weight-normal">
              Relive the 2000 Winston Cup Season with authentic car paint schemes, capturing the era's iconic designs and sponsor logos for a visually immersive experience.
              </p>
            </div>
            <hr className="vertical light" />
          </div>
          <div className="col-md-3 position-relative">
            <div className="p-3 text-center">
              <h1 className="text-gradient text-primary">
                <span className="material-icons fs-1">
                  sports_motorsports groups
                </span>
              </h1>
              <h5 className="mt-3 text-white">Drivers &amp; Teams</h5>
              <p className="text-sm font-weight-normal">
              Choose your favorite driver from the 2000 season's roster, featuring accurate statistics and driving styles. Race with legendary teams like Hendrick Motorsports and Joe Gibbs Racing.
              </p>
            </div>
            <hr className="vertical light" />
          </div>
          <div className="col-md-3 position-relative">
            <div className="p-3 text-center">
              <h1 className="text-gradient text-primary">
                <span className="material-icons fs-1">stars</span>
              </h1>
              <h5 className="mt-3 text-white">Legends</h5>
              <p className="text-sm font-weight-normal">
              Race alongside and against legendary drivers like Alan Kulwicki, Benny Parsons, Cale Yarborough and others, experiencing their virtual prowess in this nostalgic journey.
              </p>
            </div>
            <hr className="vertical light" />
          </div>
          <div className="col-md-3">
            <div className="p-3 text-center">
              <h1 className="text-gradient text-primary">
                <span className="material-icons fs-1">addsports_motorsports</span>
              </h1>
              <h5 className="mt-3 text-white">GNS Series Drivers</h5>
              <p className="text-sm font-weight-normal">
              Expand your racing challenges with Grand National Series drivers from the 2000 season, encountering rising stars and fan favorites as you progress through the mod
              </p>
            </div>
          </div>
        </div>
        <div className="row p-2 my-4">
          <div className="col-md-3 position-relative">
            <div className="p-3 text-center">
              <h1 className="text-gradient text-primary">
                <span className="material-icons fs-1">palette</span>
              </h1>
              <h5 className="mt-3 text-white">Colors</h5>
              <p className="text-sm font-weight-normal">
              Immerse yourself in the authentic atmosphere of the 2000 season with accurate team colors, capturing the vibrant hues of team liveries.
              </p>
            </div>
            <hr className="vertical light" />
          </div>
          <div className="col-md-3 position-relative">
            <div className="p-3 text-center">
              <h1 className="text-gradient text-primary">
                <span className="material-icons fs-1">checkroom</span>
              </h1>
              <h5 className="mt-3 text-white">Pit Crew Gear</h5>
              <p className="text-sm font-weight-normal">
              Enhance realism with meticulously designed pit crew gear, witnessing authentic uniforms and experiencing the crucial pit stops that define a race.
              </p>
            </div>
            <hr className="vertical light" />
          </div>
          <div className="col-md-3 position-relative">
            <div className="p-3 text-center">
              <h1 className="text-gradient text-primary">
                <span className="material-icons fs-1">record_voice_over</span>
              </h1>
              <h5 className="mt-3 text-white">Speech</h5>
              <p className="text-sm font-weight-normal">
              Feel the intensity with authentic driver-to-crew communication and spotter guidance, adding to the immersion and capturing the essence of the 2000 Winston Cup Season.
              </p>
            </div>
            <hr className="vertical light" />
          </div>
          <div className="col-md-3 ">
            <div className="p-3 text-center">
              <h1 className="text-gradient text-primary">
                <span className="material-icons fs-1">
                  addformat_list_bulleted
                </span>
              </h1>
              <h5 className="mt-3 text-white">More Features</h5>
              <p className="text-sm font-weight-normal">
              Expect ongoing updates and support as we strive to refine and expand this NASCAR Thunder 2003 mod experience. Your feedback is essential as we work to deliver an unparalleled and evolving racing simulation.
              </p>
            </div>
            <hr className="vertical light" />
          </div>
        </div>
      </div>
    </div>
    <div className="container col-xxl-12 px-4 py-3 my-4">
      <div className="row justify-content-center">
        <div className="col-lg-7">
          <h2 className=" fw-bold lh-1 mb-5 text-white text-center">
            Screenshots
          </h2>
          <div
            id="carouselExampleControls"
            className="carousel slide col carousel-fade"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner rounded-3">
              {listImages}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="container col-xxl-12 px-4 py-3 my-4">
      <div className="row justify-content-center">
        <div className="col-lg-7">
          <h2 className=" fw-bold lh-1 mb-5 text-white text-center">
            Spotify Playlist
          </h2>
          <p>Buckle up, turn up the volume, and let the music fuel your adrenaline as you take on the drivers of NASCAR Thunder 2000. is not just a playlist; it's your companion on the road to victory, adding an extra layer of excitement to every race. Hit play, and let the beats propel you to the checkered flag!</p>
          <iframe src="https://open.spotify.com/embed/playlist/0DZkmPQ83WHhmc1bGeDTx0?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div>
      </div>
    </div>
    <div className="container col-xxl-12 px-4 py-3 my-4">
      <div className="row justify-content-center">
        <div className="col-lg-7">
          <h2
            className="fw-bold lh-1 mb-5 text-white text-center"
            id="instructions"
          >
            Instructions
          </h2>
          <div className="container">
          <div className="p-3 info-horizontal">
              <div className="description ps-3">
                <h5 className="mb-0 text-white">Installation Guide</h5>
              </div>
            </div>
            <div className="p-3 info-horizontal">
              <div className="video-responsive">
              <iframe src="https://www.youtube.com/embed/oDqcgYGsYI4?si=Eq1dulDGAc7ieEmQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>
            <div className="p-3 info-horizontal">
              <div className="description ps-3">
                <h5 className="mb-0 text-white">Textures</h5>
              </div>
            </div>
            <div className="p-3 info-horizontal">
              <div className="icon icon-shape d-flex align-items-center justify-content-center">
                <span className="material-icons-round text-warning fs-1">
                  warning
                </span>
              </div>
              <div className="description ps-3">
                <p className="mb-0">
                  Remember to enable "Load custom textures" box in Graphics →
                  Advanced Settings in Dolphin Emulator.
                </p>
              </div>
            </div>
            <div className="p-3 info-horizontal">
              <div className="icon icon-shape d-flex align-items-center justify-content-center">
                <span className="material-icons-round text-warning fs-1">
                  warning
                </span>
              </div>
              <div className="description ps-3">
                <p className="mb-0">
                  If you currently have installed texture mods BACKUP and DELETE your
                  existing folder from this path: Users\youruser\Documents\Dolphin
                  Emulator\Load\Textures\GNCE69
                </p>
              </div>
            </div>
            <div className="p-3 info-horizontal">
              <div className="icon icon-shape d-flex align-items-center justify-content-center">
                <span className="material-icons-round fs-1 text-primary">
                  folder_zip
                </span>
              </div>
              <div className="description ps-3">
                <p className="mb-0">
                  Extract the content of GNCE69 folder into: <br />{" "}
                  Users\youruser\Documents\Dolphin Emulator\Load\Textures\GNCE69
                </p>
              </div>
            </div>
            <div className="p-3 info-horizontal">
              <div className="description ps-3">
                <h5 className="mb-0 text-white">Database</h5>
              </div>
            </div>
            <div className="p-3 info-horizontal">
              <div className="icon icon-shape d-flex align-items-center justify-content-center">
                <span className="material-icons-round text-warning fs-1">
                  disc_full
                </span>
              </div>
              <div className="description ps-3">
                <p className="mb-0">
                  Backup your game ISO before <br /> doing the following steps:
                </p>
              </div>
            </div>
            <div className="p-3 info-horizontal">
              <div className="icon icon-shape d-flex align-items-center justify-content-center">
                <span className="material-icons-round text-primary fs-1">
                  download
                </span>
              </div>
              <div className="description ps-3">
                <p className="mb-0">
                  Also, you will need GCRebuilder in order to replace NASCAR.db
                  file. <br />{" "}
                  <a
                    className=""
                    href="https://www.romhacking.net/utilities/619/"
                  >
                    Download Link
                  </a>
                </p>
              </div>
            </div>
            <div className="p-3 info-horizontal">
              <div className="icon icon-shape d-flex align-items-center justify-content-center">
                <span className="material-icons-round text-warning fs-1">
                  warning
                </span>
              </div>
              <div className="description ps-3">
                <p className="mb-0">Database only can be installed on .ISO, .NKIT.ISO image files
                <br/> Make sure you open these file format on GC Rebuilder.
                </p>
              </div>
            </div>
            <div className="p-3 info-horizontal pb-4">
              <div className="icon icon-shape rounded-3 bg-gradient-primary shadow-primary d-flex align-items-center justify-content-center">
                <span className="text-light fs-3">1</span>
              </div>
              <div className="description ps-3">
                <p className="mb-0">
                  Go to Image → Open → find your Nascar Thunder 2003 ISO.
                </p>
              </div>
            </div>
            <div className="p-3 info-horizontal pb-4">
              <div className="icon icon-shape rounded-3 bg-gradient-primary shadow-primary d-flex align-items-center justify-content-center">
                <span className="text-light fs-3">2</span>
              </div>
              <div className="description ps-3">
                <p className="mb-0">
                  Then, in the right side of the window, go to the file list and select NASCAR.DB file.
                  <br/> Right Click → Import
                </p>
              </div>
            </div>
            <div className="p-3 info-horizontal pb-4">
              <div className="icon icon-shape rounded-3 bg-gradient-primary shadow-primary d-flex align-items-center justify-content-center">
                <span className="text-light fs-3">3</span>
              </div>
              <div className="description ps-3">
                <p className="mb-0">Find downloaded NASCAR.DB file → Open.</p>
              </div>
            </div>
            <div className="p-3 info-horizontal">
              <div className="icon icon-shape rounded-3 bg-gradient-primary shadow-primary d-flex align-items-center justify-content-center">
                <span className="text-light fs-3">4</span>
              </div>
              <div className="description ps-3">
                <p className="mb-0">
                  Click Done, close GCRebuilder and ENJOY! 😁
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container col-xxl-12 px-4 py-3 my-4">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <h2 className="fw-bold lh-1 mb-5 text-white text-center" id="credits">
            Credits
          </h2>
          <div className="row my-2">
            <div className="col-sm-2">
              <img
                src="https://pbs.twimg.com/profile_images/1703462988810682368/r3LPuO9w_400x400.jpg"
                alt="SledgeLIX Avatar"
                className="mb-2 rounded-3"
                width={120}
                height={120}
              />
            </div>
            <div className="col">
              <h4 className="text-white">SledgeLIX</h4>
              <p>
                NT2000 MOD Developer. Car Textures conversions from NR2003 CUP2000
                Mod, 1998-1999 Chevrolet Schemes, Legend Car textures, PitCrew textures, User Interface, and
                Database editing.
              </p>
              <a
                className="btn rounded-3 bg-gradient-dark p-2 mx-1"
                href="https://x.com/SledgeLIX"
              >
                <iconify-icon
                  icon="line-md:twitter-x"
                  class="d-flex justify-content-center fs-5"
                />
              </a>
              <a
                className="btn rounded-3 bg-gradient-danger p-2 mx-1"
                href="https://www.reddit.com/user/SledgeLIX"
              >
                <iconify-icon
                  icon="line-md:reddit"
                  class="d-flex justify-content-center fs-5"
                />
              </a>
            </div>
          </div>
          <div className="container">
            <div className="p-3 info-horizontal">
              <div className="description ps-3">
                <h4 className="mb-0 text-white">Special Thanks</h4>
                <div className="row my-4">
                  <div className="col-sm-2">
                    <img
                      src="https://pbs.twimg.com/profile_images/1759783992318926848/94ctgJ50_400x400.jpg"
                      alt="Ty Thunder Avatar"
                      className="mb-2 rounded-3"
                      width={120}
                      height={120}
                    />
                  </div>
                  <div className="col">
                    <h4 className="text-white">Ty Thunder / RaddyCS999</h4>
                    <p>
                      Database modding researcher. Old Nascar Game Modder 
                      <br /> Learned a lot of his post about{" "}
                      <a
                        href="https://www.reddit.com/r/NASCARVideoGame/comments/zb5eg9/apparently_its_possible_to_mod_nascar_thunder/"
                        className=""
                      >
                        EA Nascar Games .DB editing
                      </a>
                    </p>
                    <a
                      className="btn rounded-3 bg-gradient-dark p-2 mx-1"
                      href="https://x.com/RaddyCS999"
                    >
                      <iconify-icon
                        icon="line-md:twitter-x"
                        class="d-flex justify-content-center fs-5"
                      />
                    </a>
                    <a
                      className="btn rounded-3 bg-gradient-danger p-2 mx-1"
                      href="https://www.reddit.com/user/RaddyCS999/"
                    >
                      <iconify-icon
                        icon="line-md:reddit"
                        class="d-flex justify-content-center fs-5"
                      />
                    </a>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-sm-2">
                    <img
                      src="https://pbs.twimg.com/profile_images/1590474830024949762/B-DnI6Fr_400x400.jpg"
                      alt="Baguette Avatar"
                      className="mb-2 rounded-3"
                      width={120}
                      height={120}
                    />
                  </div>
                  <div className="col">
                    <h4 className="text-white">Baguette</h4>
                    <p>
                      Knowledge about texture replacement on Nintendo Gamecube
                      emulator. <br />
                      <a
                        href="https://www.reddit.com/r/NASCAR/comments/r86w7r/many_people_have_been_asking_about_this_project/"
                        className=""
                      >
                        His NASCAR Thunder 2003 Restoration Project
                      </a>
                    </p>
                    <a
                      className="btn rounded-3 bg-gradient-dark p-2 mx-1"
                      href="https://x.com/baguettebaker"
                    >
                      <iconify-icon
                        icon="line-md:twitter-x"
                        class="d-flex justify-content-center fs-5"
                      />
                    </a>
                    <a
                      className="btn rounded-3 bg-gradient-danger p-2 mx-1"
                      href="https://www.reddit.com/user/AnalBaguette/"
                    >
                      <iconify-icon
                        icon="line-md:reddit"
                        class="d-flex justify-content-center fs-5"
                      />
                    </a>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-sm-2">
                    <img
                      src="https://stunodracing.net/data/avatars/l/1/1399.jpg?1696442488"
                      alt="CC#48 Avatar"
                      className="mb-2 rounded-3"
                      width={120}
                      height={120}
                    />
                  </div>
                  <div className="col">
                    <h4 className="text-white">CC#48</h4>
                    <p>NR2003 CUP2000 Mod 2000 Schemes</p>
                    <a
                      className="btn rounded-3 bg-gradient-info p-2 mx-2"
                      href="https://stunodracing.net/index.php?members/1399/"
                    >
                      Stunod Racing
                    </a>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-sm-2">
                    <img
                      src="https://stunodracing.net/data/avatars/l/0/50.jpg?1588869295"
                      alt="Thunder98 Avatar"
                      className="mb-2 rounded-3"
                      width={120}
                      height={120}
                    />
                  </div>
                  <div className="col">
                    <h4 className="text-white">Thunder98</h4>
                    <p>NR2003 CUP2000 Mod 1999 Ford and Pontiac Schemes</p>
                    <a
                      className="btn rounded-3 bg-gradient-dark p-2 mx-1"
                      href="https://x.com/BourbonBambino"
                    >
                      <iconify-icon
                        icon="line-md:twitter-x"
                        class="d-flex justify-content-center fs-5"
                      />
                    </a>
                    <a
                      className="btn rounded-3 bg-gradient-danger p-2 mx-1"
                      href="https://www.reddit.com/user/Thunder98_/"
                    >
                      <iconify-icon
                        icon="line-md:reddit"
                        class="d-flex justify-content-center fs-5"
                      />
                    </a>
                    <a
                      className="btn rounded-3 bg-gradient-info p-2 mx-1"
                      href="https://stunodracing.net/index.php?members/thunder98.50/"
                    >
                      Stunod Racing
                    </a>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-sm-2">
                    <img
                      src="https://stunodracing.net/data/avatars/m/5/5342.jpg?1671145973"
                      alt="NascarFan96 Avatar"
                      className="mb-2 rounded-3"
                      width={120}
                      height={120}
                    />
                  </div>
                  <div className="col">
                    <h4 className="text-white">NascarFan96</h4>
                    <p>NR2003 CUP2000 Mod GNS 2000 Schemes</p>
                    <a
                      className="btn rounded-3 bg-gradient-danger p-2 mx-1"
                      href="https://www.reddit.com/user/Obvious_Lobster_6318/"
                    >
                      <iconify-icon
                        icon="line-md:reddit"
                        class="d-flex justify-content-center fs-5"
                      />
                    </a>
                    <a
                      className="btn rounded-3 bg-gradient-info p-2 mx-1"
                      href="https://stunodracing.net/index.php?members/nascarfan96.5342/"
                    >
                      Stunod Racing
                    </a>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-sm-2">
                    <img
                      src="https://pbs.twimg.com/profile_images/1401712481932824576/SQRIkiyl_400x400.jpg"
                      alt="2002 Pontiac Grand Prix Avatar"
                      className="mb-2 rounded-3"
                      width={120}
                      height={120}
                    />
                  </div>
                  <div className="col">
                    <h4 className="text-white">2002 Pontiac Grand Prix</h4>
                    <p>For his research about game database and other interesting findings</p>
                    <a
                      className="btn rounded-3 bg-gradient-dark p-2 mx-1"
                      href="https://twitter.com/2002GrandPrix"
                    >
                      <iconify-icon
                        icon="line-md:twitter-x"
                        class="d-flex justify-content-center fs-5"
                      />
                    </a>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-sm-2">
                    <img
                      src="https://stunodracing.net/data/avatars/l/13/13862.jpg?1697305885"
                      alt="sensori Avatar"
                      className="mb-2 rounded-3"
                      width={120}
                      height={120}
                    />
                  </div>
                  <div className="col">
                    <h4 className="text-white">sensori</h4>
                    <p>NR2003 CUP2000 Mod GNS 2000 #8 #45 and #53 Schemes</p>
                    <a
                      className="btn rounded-3 bg-gradient-info p-2 mx-2"
                      href="https://stunodracing.net/index.php?members/sensori.13862/"
                    >
                      Stunod Racing
                    </a>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-sm-2">
                    <img
                      src="https://pbs.twimg.com/profile_images/1622798598164590598/mU140Ysi_400x400.jpg"
                      alt="Luis Torres Avatar"
                      className="mb-2 rounded-3"
                      width={120}
                      height={120}
                    />
                  </div>
                  <div className="col">
                    <h4 className="text-white">Luis Torres</h4>
                    <p>Found 2000 Drivers Portraits on NASCAR Pictorial Archive Facebook</p>
                    <a
                      className="btn rounded-3 bg-gradient-dark p-2 mx-1"
                      href="https://twitter.com/TheLTFiles"
                    >
                      <iconify-icon
                        icon="line-md:twitter-x"
                        class="d-flex justify-content-center fs-5"
                      />
                    </a>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col">
                    <h4 className="text-white">SiKey</h4>
                    <p>NR2003 CUP2000 Mod #36 M&M's Halloween Scheme</p>
                    <a
                      className="btn rounded-3 bg-gradient-danger p-2 mx-1"
                      href="https://www.reddit.com/user/AdHuman9506/"
                    >
                      <iconify-icon
                        icon="line-md:reddit"
                        class="d-flex justify-content-center fs-5"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</>
  )
}

export default NT2000