import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DownloadDetails from "./DownloadDetails";
import PageNotFound from "./PageNotFound";
import Loading from "./Loading";

const DownloadDetailsContainer = () => {
    const [data, setData] = useState([]);
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const getData = async() => {
          try {
            const res = await axios.get('https://us-central1-sledgemods-site.cloudfunctions.net/api/downloads/'+id);
            setData(res.data);
            setLoading(false);
          } catch (error) {
            console.log(error)            
          }
        };
        getData();
      },[id]);
    
  if (loading) {
      return <Loading/>
    }

  return (
    data._id ? <DownloadDetails item={data}/> : <PageNotFound/>
  )
}

export default DownloadDetailsContainer