const Loading = () => {
  return (
    <div className="card-body cart my-5">
      <span className="py-5"></span>
      <div className="col-sm-12 empty-cart-cls text-center">
      <div className="my-5 py-5" />
        <div className="d-flex justify-content-center my-5">
          <span className="spinner"></span>
        </div>      
      <h2 className="d-flex justify-content-center my-5 text-white"><strong>Loading...</strong></h2>
      </div>
  </div>
  )
}

export default Loading