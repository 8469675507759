import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

const ButtonDownload = ({btn}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClick = () => {handleShow(); btn();}
  return (
    <>
      <Button className='bg-gradient-primary me-4 btn-lg px-3' onClick={handleClick}>
      <i className="material-icons me-2" aria-hidden="true">download</i>Download
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <div className='bg-gradient-dark rounded-3 border-1 border border-primary'>
              <div className="modal-header border-0" closeButton>
                  <h5 className="modal-title text-white">
                      Thanks for downloading! 😁
                  </h5>
                  <button type="button" className="btn-close" onClick={handleClose}/>
              </div>
              <div className="modal-body border-0">
                  <p className="fs-5 text-white text-center">
                      If you enjoyed this content, <br /> Please, make a
                      donation, It would help me a lot <br />
                      👋
                      <a href='https://www.buymeacoffee.com/Sledgelix'>Buy me a Coffee ☕</a>
                  </p>
              </div>
              <div className="modal-footer border-0">
                  <Link to={'/donate'} className="btn bg-gradient-primary" onClick={handleClose}>
                      Donate
                  </Link>
                  <button type="button" className="btn bg-gradient-light" onClick={handleClose}>
                      Close
                  </button>
              </div>
        </div>
      </Modal>
    </>
  )
}

export default ButtonDownload