import { Link } from "react-router-dom";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import heroNT2000 from '../assets/img/heroNT2000.png';
import heroN05CFTCtemps from '../assets/img/heroN05CFTCtemps.png'
import SledgeModsLogo from '../assets/img/logos/SledgeModsLogo.png';
import ModalVideo from './modals/ModalVideo';
// import { Adsense } from '@ctrl/react-adsense';

const Main = ({items}) => {
  const arrItems = items.sort(({timestamp:a}, {timestamp:b}) => b-a).slice(0,3);

  function showItems(items) {
    return items.map((item) => (
      <div className="col-lg-7 my-3">
      <Link to={item.hasOwnProperty('customURL') ? '/downloads'+item.customURL : '/downloads/'+item._id}>
        <div className="card move-on-hover bg-gradient-dark border-1 border border-primary">
          <img className="w-100" src={item.thumbnailURL} alt="Mod Image" />
        </div>
        <div className="mt-2 ms-2">
          <h6 className="mb-0 text-primary">{item.title}</h6>
        </div>
      </Link>
    </div>      
    ));
  }

  // function showItemsHeader(items) {
  //   return items.map((item) => (
  //     <div className="carousel-item active">
  //     <div className="page-header min-vh-100" style={{backgroundImage: `url(${item.mainImage})`}} loading="lazy">
  //       <span className="mask bg-gradient-dark opacity-5" />
  //       <div className="container">
  //         <div className="row">
  //           <div className="col-lg-6 col-md-7 d-flex justify-content-center flex-column">
  //             <div className="container">
  //               <div className="boxart">
  //                 <Link to={item.hasOwnProperty('customURL') ? `/downloads${item.customURL}` : `/downloads/${item._id}` }>
  //                   <div>
  //                     <img className src="" alt="" width={item.mainThumbWidth} height={item.mainThumbHeight} />
  //                     <div>
  //                     </div>
  //                   </div>
  //                 </Link>
  //               </div>
  //             </div>
  //             <h1 className="text-white my-4">{item.title}</h1>
  //             <p className="text-white lead pe-5 me-5">{item.mainDescription}</p>
  //             <div className="buttons">
  //               <button className="btn bg-gradient-light mt-4" type="button">
  //                 <div className="d-flex align-items-center">
  //                   <i className="material-icons me-2" aria-hidden="true">movie</i>
  //                   Watch Trailer
  //                 </div>
  //               </button>
  //               <Link className="btn text-white shadow-none mt-4" to={item.hasOwnProperty('customURL') ? `/downloads${item.customURL}` : `/downloads/${item._id}`}>Read more</Link>
  //               {/* <a href="./nt2000.html" className="btn text-white shadow-none mt-4">Read more</a> */}
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>  
  //   ));
  // }

  return (
    <div>
    <header>
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" />
          <li data-target="#carouselExampleIndicators" data-bs-slide-to={1} />
          {/* <li data-target="#carouselExampleIndicators" data-bs-slide-to={2} /> */}
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="page-header min-vh-100" style={{backgroundImage: `url(${heroNT2000})`}} loading="lazy">
              <span className="mask bg-gradient-dark opacity-5" />
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-7 d-flex justify-content-center flex-column">
                    <div className="container">
                      <div className="boxart">
                        <Link to={'/Downloads/NT2000'}>
                          <div>
                            <img className src="./assets/img/screenshots/coverFront.png" alt="" width={190} height={254} />
                            <div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h1 className="text-white my-4">NASCAR Thunder 2000</h1>
                    <p className="text-white lead pe-5 me-5">Get ready to step back in time and experience the exhilarating world of NASCAR racing in an all-new way with this 2000 Season Mod for NASCAR Thunder 2003 (NGC)</p>
                    <div className="buttons">
                      <Link to={'/Downloads/NT2000'} className="btn bg-gradient-primary mt-4 me-3"><i className="material-icons me-2" aria-hidden="true">download</i> Download</Link>
                      <ModalVideo btnText={'Trailer'} classStr={'btn bg-gradient-primary mt-4'} videoURL={'https://www.youtube.com/embed/GWkvr1grBoY?si=ut3dFn4pbO_YvAoN'}></ModalVideo>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div className="page-header min-vh-100" style={{backgroundImage: `url(${heroN05CFTCtemps})`}} loading="lazy">
              <span className="mask bg-gradient-dark opacity-5" />
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-7 d-flex justify-content-center flex-column">
                    <h1 className="text-white my-4">NASCAR 2005 Chase For The Cup Templates</h1>
                    <p className="text-white lead pe-5 me-5">Templates available in the downloads section. Now you can create your own liveries!</p>
                    <div className="buttons">
                      <Link to={'/Downloads/65935875f5e50d32d57e78ae'} className="btn bg-gradient-primary mt-4" type="button">
                        <div className="d-flex align-items-center">
                          <i className="material-icons me-2" aria-hidden="true">download</i>
                          Download
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
          <span className="carousel-control-prev-icon me-5" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
          <span className="carousel-control-next-icon me-5" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </a>
      </div>
    </header>
    <div className="container px-4 py-3 m3-5">
      <div className="row justify-content-center my-4">
        <div className="col-xxl-12">
          {/* <div className="container mb-2">
          <Adsense
          client="ca-pub-6090037812647192"
          slot="4377054626"
          style={{ display: 'block' }}
          layout="in-article"
          format="fluid"
        />
        </div> */}
          <h2 className="fw-bold lh-1 mb-3 text-white text-center"><img src={SledgeModsLogo} alt="" width={250} /></h2>
          <div className="container">
            <div className="p-3 info-horizontal">
              <p>Welcome, I'am SledgeLIX, a passionate modder dedicated to creating and sharing mods for some popular racing games.</p>
              <p>Whether you are a seasoned modder or just starting out, my website is home to a growing library of mods, from simple car skins to complex gameplay overhauls. I am constantly working to expand my collection, and I am always open to suggestions from users.</p>
              <p>In addition to my mod library, I also offer a variety of resources to help you learn how to mod racing games. There is a <Link to={'./faq'}>guides</Link> section, <br /> and a <a className href="https://x.com/SledgeLIX"><iconify-icon icon="line-md:twitter-x" className="text-white" /> account</a> where I share my modding knowledge and experiences.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center my-4">
        <div className="col-sm-6 mb-5">
          <h3 className="fw-bold lh-1 mb-3 text-white text-center d-flex justify-content-center">Latest &nbsp;<iconify-icon icon="line-md:twitter-x" className="text-white text-center" />&nbsp; Posts</h3>
          <div className="container">
            <div className="p-3 info-horizontal row justify-content-center">
            <TwitterTimelineEmbed sourceType="profile" screenName="sledgelix" options={{height: 800, width: 500}}/>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <h3 className="fw-bold lh-1 mb-3 text-white text-center">Latest Downloads </h3>
          <div className="container row justify-content-center">
            {showItems(arrItems)}
          </div>
        </div>
      </div>
      {/* <div className="container mb-2">
          <Adsense
          client="ca-pub-6090037812647192"
          slot="4377054626"
          style={{ display: 'block' }}
          layout="in-article"
          format="fluid"
        />
        </div> */}
    </div>
  </div>
  )
}

export default Main