import { Link } from "react-router-dom"

const PageNotFound = () => {
  return (
    <div className="card-body cart my-5">
      <div className="col-sm-12 empty-cart-cls text-center">
      <img className='mb-5 img-fluid mr-2' src width="450"/>
      <h2 className="text-white"><strong>ERROR 404</strong></h2>
        <h1 className='text-danger my-5'><span className="material-icons-round bg-light rounded-circle fs-1">error</span></h1>
        <h2 className="text-white"><strong>This page doesn't exist!</strong></h2>
        <div className="my-5">
            <Link to='/' className="btn btn-lg bg-gradient-primary">Back</Link>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound