import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import MainContainer from './components/MainContainer';
import DownloadsDetailsContainer from './components/DownloadDetailsContainer';
import DownloadsListContainer from './components/DownloadsListContainer';
import ScrollToTop from './components/ScrollToTop';
import { Faq, Donate } from './components/Static';
import NT2000Container from './components/NT2000Container';
import PageNotFound from './components/PageNotFound';

function App() {

  return (
    <HashRouter>
    <div className="App d-flex flex-column min-vh-100">
      {/* <link rel='stylesheet' href='/App.css'></link> */}
      <ScrollToTop/>
      <Navbar/>
        <Routes>
          <Route path='/' element={<MainContainer/>}/>
          <Route path='/downloads/NT2000' element={<NT2000Container/>}/>
          <Route path='/downloads/' element={<DownloadsListContainer/>}/>
          <Route path='/downloads/:id' element={<DownloadsDetailsContainer/>}/>
          <Route path='/FAQ' element={<Faq/>}/>
          <Route path='/donate' element={<Donate/>}/>
          <Route path='*' element={<PageNotFound/>}/>
        </Routes>
      <Footer/>
    </div>
    </HashRouter>

    // <BrowserRouter>
    // <div className="App d-flex flex-column min-vh-100">
    //   {/* <link rel='stylesheet' href='/App.css'></link> */}
    //   <ScrollToTop/>
    //   <Navbar/>
    //   <Routes>
    //     <Route path='/' element={<MainContainer/>}/>
    //     <Route path='/downloads/NT2000' element={<NT2000Container/>}/>
    //     <Route path='/downloads/' element={<DownloadsListContainer/>}/>
    //     <Route path='/downloads/:id' element={<DownloadsDetailsContainer/>}/>
    //     <Route path='/FAQ' element={<Faq/>}/>
    //     <Route path='/donate' element={<Donate/>}/>
    //     <Route path='*' element={<PageNotFound/>}/>
    //   </Routes>
    //   <Footer/>
    // </div>
    // </BrowserRouter>
  );
}

export default App;
