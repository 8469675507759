import { Link } from "react-router-dom";
import ModalShare from "./modals/ModalShare";

const DownloadsList = ({items}) => {

  function showItems(items, categories) {
    const filteredItems = items.filter((item) => item.category === categories);
    return filteredItems.map((item) => (
          <div className="col">
            <div className="card shadow-sm bg-gradient-dark border-1 border border-primary h-100">
              <img className="card-img-top" src={item.thumbnailURL} />
              <div className="card-body">
                <h4 className="text-white">{item.title}</h4>
                <div className="mb-3">
                  <span className="card-text me-2 text-white">Author: <span className="text-primary"> {item.author} </span> </span>
                  <br/>
                  <span className="card-text me-2 text-white">Uploaded: <span className="text-primary"> {Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(item.timestamp)} </span> </span>
                  <br/>
                  <span className="card-text me-2 text-white">File Size: <span className="text-primary"> {item.size} </span> </span>
                  <br/>
                  <span className="card-text me-2 text-white">Downloads: <span className="text-primary"> {item.qtyD} </span> </span>       
                  <br/>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  {
                    item.hasOwnProperty('customURL') ? <Link to={`/downloads${item.customURL}`} className="btn btn-sm bg-gradient-primary mb-0">View</Link> : <Link to={`/downloads/${item._id}`} className="btn btn-sm bg-gradient-primary mb-0">View</Link>
                  }
                  <ModalShare currentURL={window.location.href + (item.hasOwnProperty('customURL') ? item.customURL : `/${item._id}`) } classStr={"btn btn-sm bg-gradient-primary mb-0"}></ModalShare>
                </div>
              </div>
            </div>
          </div>
    ));
  }
  const arrCat = ['NASCAR Thunder 2003', 'NASCAR 2005 Chase For The Cup', 'Tools & Utilities']

  function showCategories(){
    return arrCat.map((category) => (
      <div className="container my-5">
      <h3 className="my-3 text-primary">{category}</h3>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        {items ? showItems(items, category) : <div class="spinner"></div>}
      </div>
    </div>
    ))
  }

  return (
<>
  <div className="container px-4 py-4 my-2" />
  <div className="container px-4 pb-3">
    <div className="album">
      {items ? showCategories() : <div class="spinner"></div> }
    </div>
  </div>
</>
  )
}

export default DownloadsList