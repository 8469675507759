import { Link } from 'react-router-dom'
import SMLogo from '../assets/img/logos/SledgeModsLogo.png'

const Navbar = () => {
    return (
        <div className="position-sticky z-index-sticky top-0">
        <div className>
          <div className>
            <nav className="navbar navbar-expand-lg position-absolute top-0 z-index-3 w-100 shadow mb-3 navbar-transparent bg-gradient-dark">
              <div className="container">
                <Link to={'/'} className="navbar-brand text-white fs-5" rel="tooltip" title="Designed and Coded by Creative Tim" data-placement="bottom">
                  <img src={SMLogo} alt="" width={130} />
                </Link>
                <button className="navbar-toggler shadow-none ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon mt-2">
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </span>
                </button>
                <div className="collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0 ms-lg-12 ps-lg-8" id="navigation">
                  <ul className="navbar-nav navbar-nav-hover w-100">
                    <li className="nav-item dropdown dropdown-hover mx-2">
                      <Link to={'/downloads'} className="nav-link ps-2 d-flex cursor-pointer align-items-center text-lg" id="dropdownMenuBlocks" aria-expanded="false">
                        Downloads
                      </Link>
                      <ul className="dropdown-menu dropdown-menu-animation dropdown-lg dropdown-lg-responsive border-radius-lg mt-0 mt-lg-3 rounded-0 blur" aria-labelledby="dropdownMenuBlocks">
                        <div className="">
                        <li className="nav-item ">
                          <Link to={'/downloads'} className="dropdown-item py-2 ps-3 border-radius-md rounded-0">
                                <div className="d-flex">
                                  <div className="icon h-10 me-3 d-flex mt-1 justify-content-center align-items-center">
                                    <i className="material-icons-round text-white">sports_esports</i>
                                  </div>
                                  <h6 className="dropdown-header text-dark font-weight-bolder d-flex justify-content-center align-items-center p-0 text-white">
                                  NASCAR Thunder 2003 (NGC)</h6>
                                </div>
                            </Link>
                          </li>
                          <li className="nav-item">
                          <Link to={'/downloads'} className="dropdown-item py-2 ps-3 border-radius-md rounded-0">
                                <div className="d-flex">
                                  <div className="icon h-10 me-3 d-flex mt-1 justify-content-center align-items-center">
                                    <i className="material-icons-round text-white">sports_esports</i>
                                  </div>
                                  <h6 className="dropdown-header font-weight-bolder d-flex justify-content-center align-items-center p-0 text-white">
                                  NASCAR 2005: CFTC (NGC)</h6>
                                </div>
                            </Link>
                          </li>
                          <li className="nav-item ">
                          <Link to={'/downloads'} className="dropdown-item py-2 ps-3 border-radius-md rounded-0">
                                <div className="d-flex">
                                  <div className="icon h-10 me-3 d-flex mt-1 justify-content-center align-items-center">
                                  <i className="material-icons text-white">build</i>
                                  </div>
                                  <h6 className="dropdown-header font-weight-bolder d-flex justify-content-center align-items-center p-0 text-white">
                                  Tools &amp; Utilities</h6>
                                </div>
                            </Link>
                          </li>
                        </div>
                      </ul>
                    </li>
                    <li className="nav-item dropdown dropdown-hover mx-2">
                      <Link to={'/FAQ'} className="nav-link ps-2 d-flex cursor-pointer align-items-center text-lg" id="dropdownMenuDocs" aria-expanded="false">
                        Guides
                      </Link>
                      <ul className="dropdown-menu dropdown-menu-animation dropdown-lg mt-0 mt-lg-3 border-radius-lg rounded-0 blur" aria-labelledby="dropdownMenuDocs">
                          <li className="nav-item ">
                            <Link to={'/faq#texdolphin'} className="dropdown-item py-2 ps-3 border-radius-md rounded-0">
                              <div className="d-flex">
                                <div>
                                  <h6 className="dropdown-header text-white font-weight-bolder d-flex justify-content-center align-items-center p-0">
                                    How to replace textures on Dolphin Emulator</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li className="nav-item ">
                            <Link to={'/faq#dumpdolphin'} className="dropdown-item py-2 ps-3 border-radius-md rounded-0">
                              <div className="d-flex">
                                <div>
                                  <h6 className="dropdown-header text-white font-weight-bolder d-flex justify-content-center align-items-center p-0">
                                    How to Dump Textures on Dolphin</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                      </ul>
                    </li>
                    <li className="nav-item ms-lg-auto my-2 mx-2">
                      <Link to={'/donate'} className="btn btn-sm bg-gradient-primary btn-round mb-0 me-3 mt-2 mt-md-0">Donate</Link>
                      <a href="https://www.buymeacoffee.com/Sledgelix" className='mb-0 me-1 mt-2 mt-md-0'><img width={150} src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=Sledgelix&button_colour=FFDD00&font_colour=000000&font_family=Lato&outline_colour=000000&coffee_colour=ffffff" /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div></div></div>
    );
}
export default Navbar;