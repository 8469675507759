const Footer = () => {
    return (
        <footer className="d-flex flex-wrap justify-content-center align-items-center py-3 mt-4 bg-gradient-dark mt-auto">
        <div className="col-md-4 d-flex align-items-center">
            <span className="text-muted ms-4">© 2023 Sledge Mods</span>
        </div>

        <ul class="nav col-md-4 justify-content-end list-unstyled me-4">
            <li className="ms-3"><a className="btn rounded-3 bg-gradient-dark p-2 d-flex align-items-center justify-content-center mb-0" href="https://x.com/SledgeLIX"><iconify-icon icon="line-md:twitter-x" class="fs-5"></iconify-icon></a></li>
            <li className="ms-3"><a className="btn rounded-3 bg-gradient-danger p-2 text-center d-flex align-items-center justify-content-center mb-0" href="https://www.reddit.com/user/SledgeLIX"><iconify-icon icon="line-md:reddit" class="fs-5 me-2"></iconify-icon>Reddit</a></li>
        </ul>
        </footer>
    );
}
export default Footer;