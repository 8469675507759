import axios from "axios";
import { useEffect, useState } from "react";
import Main from "./Main";
import Loading from "./Loading";

const MainContainer = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const getData = async() => {
          try {
            const res = await axios.get('https://us-central1-sledgemods-site.cloudfunctions.net/api/downloads/');
            setData(res.data);
            setLoading(false);
          } catch (error) {
            console.log(error)            
          }
        };
        getData();
      },[]);

  if (loading) {
      return <Loading/>
    }

  return (
    <Main items={data}/>
  )
}

export default MainContainer