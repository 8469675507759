import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalVideo = ({btnText,videoURL,classStr}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
      <Button className={classStr} onClick={handleShow}>
      <i className="material-icons me-2" aria-hidden="true">movie</i>{btnText}
      </Button>
      <Modal size="lg" show={show} onHide={handleClose} centered>
        <div className='bg-gradient-dark rounded-3 border-1 border border-primary'>
                <div style={{ backgroundColor: "transparent" }} className="modal-content bg-gradient-dark rounded-3">
                  <div className="modal-body">
                  <div className='video-responsive'>
                    <iframe width="765" height="430" src={videoURL} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  </div>
                  </div>
                </div>
              </div>
      </Modal>
    </>
  )
}

export default ModalVideo